import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import { venomModule } from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    online: true,
  },
  actions: {
    checkOnline( context, value ) {
      context.commit("CHECK_ONLINE", value);
    }
  },
  mutations: {
    CHECK_ONLINE(state, payload) {
      state.online = navigator.onLine || payload;
    }
  },
  getters: {
  },
  modules: {
    venom: venomModule,
  },
  plugins: [
    createPersistedState({
      key: 'cms3-session',
      storage: window.sessionStorage,
      fetchBeforeUse: true
    })
  ]
});
