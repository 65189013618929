<template>

  <div class="dark-background">
    <div class="half-opacity">
      <div class="body-bg-pink"></div>
      <div class="body-bg-blue"></div>
      <div class="body-bg-pink-2 "></div>
      <div class="body-bg-blue-2 "></div>
    </div>

    <div id='top-nav' class="front-navbar uk-navbar-container uk-navbar-transparent uk-flex-between uk-position-top uk-position-fixed uk-position-z-index uk-width-1-1" uk-navbar>
      <span class="uk-logo uk-padding uk-margin-large-left" >
        <a href="https://iconicengine.com">
          <img src="../assets/IE-Logo.png" alt="IE Logo" style="height: 30px!important">
        </a>
      </span>
      <span class="uk-padding">
        <a class="uk-margin-right uk-link-text" @click="navigate('Login')"> Log in</a>
        <GradientButton class="uk-button uk-margin-large-right" @click.native="navigate('SignUp')" text='Sign up' />
      </span>
    </div>

    <div class="uk-inline uk-width-1-1  uk-animation-fade">
      <img src="../assets/BG UNG.png" alt="VR BG" class="uk-width-1-1">
      <div class="uk-overlay uk-position-cover bg-linear-gradient">
        <div class="uk-position-center uk-text-center" style="max-width: 30%">
          <div class="uk-text front-headline" >
            Start using CMS3 today!
          </div>
          <div class="uk-flex-inline uk-margin-small">
            <GradientButton class="uk-button " @click.native="navigate('SignUp')" text='Sign up!' />
            <GradientButton class="uk-margin-left uk-button " @click.native="navigate('Login')" text='Log in' />
            <!-- <button class="uk-button uk-button-primary uk-margin-small-right" @click="navigate('SignUp')">Sign up!</button>
            <button class=" uk-button uk-button-primary uk-margin-small-left" @click="navigate('Login')">Log in</button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="uk-container uk-margin-large" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500">
      <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin neutral-card" uk-grid>
        <div class="uk-card-media-left uk-cover-container uk-overflow-hidden">
            <img src="../assets/cms-computer-1.png" alt="" uk-cover style="border-top-left-radius: 22px; border-bottom-left-radius: 22px;">
            <canvas width="600" height="400"></canvas>
        </div>
        <div class="uk-margin-auto-vertical">
            <div class="uk-card-body">
              <div class="uk-padding uk-text-left">
                <h3 class="front-headline uk-text-break">Manage content Flawlessly.</h3>
                <h5 class="front-card-header uk-margin-remove-top uk-text-break">Analyze Easily. Engage Authentically.</h5>
                <p class="uk-text uk-text-muted front-card-body">CMS3 allows you to seamlessly manage your content and analyze data with a complete, easy to understand overview of all projects.</p>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="uk-container" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 250">
      <div class="uk-grid-medium uk-child-width-expand" uk-grid  uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 250">

        <div class="uk-width-1-3"  >
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/RealTime.png" alt="Real Time">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold"> Real Time </h3>
                <p class="front-card-body uk-text-muted"> Need to make that piece of content available in your metaverse?
With CMS3, your authorized team members can instantly upload and make it available for your audiences.  </p>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-3">
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/360.png" alt="360 Videos">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold"> 2D & 360 Videos </h3>
                <p class="front-card-body uk-text-muted"> Caters to all your video library needs, such as video on demand and live streaming! Upload, transcode, manage, and publish your videos in a variety of formats with ease. </p>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-3">
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/Lock.png" alt="Restrict">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold">Complete Control</h3>
                <p class="front-card-body uk-text-muted">Based on your content strategy and licenses, you can target content by region and time giving you total control of providing more relevant content to your audiences.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-3">
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/PN.png" alt="Push Notifications">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold"> Push Notifications</h3>
                <p class="front-card-body uk-text-muted"> Send messages or content related updates, directly to your customers as push-notifications targeted across various applications on different platforms and geo locations. </p>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-3">
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/Advertising.png" alt="Advertising">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold"> Advertising </h3>
                <p class="front-card-body uk-text-muted"> Create and insert content-specific ads into your apps with ease, then deliver them to your customer base with just a few clicks to begin monetizing. </p>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-width-1-3">
          <div class="uk-card uk-card-default neutral-card uk-padding-remove uk-text-left">
            <div class="uk-card-body long-card">
              <span class="uk-logo">
                <img src="../assets/Analytics.png" alt="Analytics">
              </span>
              <div class="uk-margin">
                <h3 class="front-card-header uk-text-bold"> Analytics  </h3>
                <p class="front-card-body uk-text-muted">Start learning more about your community, such as time they spend on your apps, their platforms and location, the content that managed to draw the most interest, and so on.</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <div class="uk-margin-large uk-margin-large-bottom uk-position-z-index" >
        <h3 class="uk-text front-headline">About CMS3</h3>
        <p class="uk-text uk-text-muted">CMS3 is operated by Iconic Engine,  It has been built, stress tested, and polished over the years to suit the content management needs of trusted brands such as Orange and Deutsche Telekom.  
          Learn more about Iconic engine at the <a target='_blank' href="https://www.iconicengine.com" class="no-hover">Iconic Engine website.</a> </p>

        <a href="https://www.iconicengine.com/">
          <GradientButton class="uk-button" :text="'Learn more about Iconic Engine'" />
        </a>

      </div>

    </div>

    <div id='top-nav' class="front-footer uk-flex-between uk-position-z-index uk-width-1-1" uk-navbar>
      <span class="uk-logo uk-padding uk-margin-large-left" >
        <a href="https://iconicengine.com" >
          <img src="../assets/IE-Logo.png" alt="IE Logo" style="height: 18.5px!important">
        </a>
      </span>
      <span class="uk-padding" style="padding-top:47px">
        <span class="uk-margin-right uk-text-muted">Copyright ©Iconic Engine 2023</span>
      </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GradientButton from "@/components/buttons/GradientButton";

export default {
  name: "LandingPage",
  components: {
    GradientButton
  },
  methods: {
    navigate (destination) {
      this.$router.push({ name: destination })
    },
    btnLogin() {
      this.$router.push({ name: "Login" });
    },
    btnRegister() {
      this.$router.push({ name: "SignUp" });
    }
  }
};
</script>

<style lang="scss" scoped>
.no-hover {
  color: var(--app-primary-color)
}
.uk-navbar-transparent {
  background: none;
}
.hero-image {
  width: 100%;
}
.bg-linear-gradient {
  // background: linear-gradient(360deg, rgba(33, 30, 40, 0.63) 0.58%, rgba(0, 0, 0, 0) 48.16%)!important;
  background: rgba(0, 0, 0, 0.63);
}

.dark-background {
  background: #131319;
}


.neutral-card {
  border: 2px solid rgba(255, 255, 255, 0.06);
  /* Card/Large Shadow */
  box-shadow: 0px 14px 74px rgba(0, 0, 0, 0.45);
  border-radius: 22px;
  background: #1D1A27;
}

.front-headline {
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: #FFFFFF;
}

.front-card-header {
  font-size: 24px;
  color: #FFFFFF;
}
.front-card-body {
  font-size: 18px;
  line-height: 30px;
}

.front-navbar {
  background: rgba(0, 0, 0, 0.17);
}

.front-footer {
  background: #13111A;
}

.body-bg-pink {
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  left: -155.4px;
  top: 941px;
  filter: blur(100px);

  /* Blob/Pink */

  // background: #FF4895;
  background: radial-gradient(#FF4895, transparent);
}

.body-bg-blue {
  /* Ellipse 412 */
  position: absolute;
  width: 561.35px;
  height: 561.35px;
  left: 216.77px;
  top: 1414.02px;
  filter: blur(100px);
  background: radial-gradient(#2D42FF 20%, transparent 100%);
}

.body-bg-blue-2 {
  /* Ellipse 410 */
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  right: 200px;
  top: 1895.82px;
  filter: blur(100px);
  border-radius: 100000px;
  background: #2D42FF;
}

.body-bg-pink-2 {
  /* Ellipse 411 */
  position: absolute;
  width: 578.87px;
  height: 578.87px;
  right: 100.6px;
  top: 1490.04px;

  /* Blob/Pink */
  filter: blur(100px);
  border-radius: 1000000px;
  background: #FF4895;
}

.half-opacity {
  opacity: .5;
  z-index: -1;
}

.long-card {
  height: 300px;
}

</style>
