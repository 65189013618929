// App Specific Configuration File

// Using CommonJS instead of ES2015+ export, because we also need to
// provide this object to Webpack in vue.config.js.
module.exports = {
  title: 'Iconic Engine | CMS3',
  tagline: 'Content Management System',
  description: 'XR Content Management Suite',
  theme: process.env.VUE_APP_THEME || 'dark',
  backend: {
    server:"https://venom-api-prod.main.eks.venom360.com/graphql",
    ws: process.env.VUE_APP_VENOM_WSS_URL || 'wss://api.iconicengine.com/graphql',
    venom_api_base_url: process.env.VUE_APP_VENOM_API_BASE || 'https://venom-api-prod.main.eks.venom360.com',
    interactive_player_url: process.env.VUE_APP_INTERACTIVE_PLAYER_URL || 'https://ivy.player.iconicengine.com',
    media_player_url: process.env.VUE_APP_MEDIA_PLAYER_URL || 'https://ivy.player.iconicengine.com',
    analytics_export_url: process.env.VUE_APP_EXPORTER_API_BASE || 'https://kube.venom360.com/hybrid/export'
  },
  iv: {
    editor: process.env.VUE_APP_VENOM_IV_EDITOR_URL || 'https://editor.iconicengine.com',
    interactive_player_embed_url: process.env.VUE_APP_INTERACTIVE_PLAYER_EMBED_URL || 'https://ivy.player.iconicengine.com'
  },
  sentry: {
    dsn: "https://6771cfc4c32145e49ffdcc97e1294176@o74881.ingest.sentry.io/6022301",
    tracingOrigins: ["localhost", "dq6ymj4zqh3mu.cloudfront.net", "cms.iconicengine.com", "cms3.iconicengine.com", /^\//],
    denyUrls: [/extensions\//i,
      /^chrome:\/\//i],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  },
  sec: {
    passwordChangeFrequency: 30,
    autoLogin: true,
  }
}
