import cookies from 'vue-cookies';

export function isLoggedIn () {
  const token = cookies.get('auth-token');
  const expDate = cookies.get('token-exp');
  const now = new Date().getTime()/1000;
  const tokenExpired = (now - expDate > 0) ? true : false; 

  if (!token || tokenExpired) {
    return false
  } else {
    return true
  }
}

// User Authentication and Token Handling Functions
export function auth({ next, router }) {
  if (isLoggedIn()) {
    return next();
  } else {
    removeUserToken();
    const redirect = router.history._startLocation || null;
    return router.push({ name: 'Login', params: {redirect} });
  }
}

export function getUserToken() {
  return cookies.get('auth-token')

  // return sessionStorage.getItem('auth-token');
}

export function setUserToken( token ) {
  let domain = (new URL(window.location));
  if( domain.host === 'cms3.iconicengine.com' || domain.host === 'beta.cms3.iconicengine.com' )
    return cookies.set('auth-token', token, null, null, 'iconicengine.com');
  else 
    return cookies.set('auth-token', token ); 
  // return sessionStorage.setItem('auth-token', token);
}
export function setUserExpToken( exp ) {
  return cookies.set('token-exp', exp );
}

export function removeUserToken() {
  return cookies.remove('auth-token')
  // return sessionStorage.removeItem('auth-token');
}

export function removeUserExpToken() {
  return cookies.remove('token-exp')
}
