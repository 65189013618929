<template>
  <div class="button-gradient">
    <button
      class="small-button button-primary"
    >{{text}}</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GradientButton',
  props: {
    size: String,
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
        theme: state => state.venom.theme
    }),
  }
}
</script>

<style scoped>
  .button-primary {
      padding: 22px 56px;
      border-radius: 1000px;
      background-color: #13111a;

      color: var(--text-color);
      /* line-height: 1.111em; */
      /* font-weight: 700; */
      text-align: center;
      /* letter-spacing: .01em; */
  }
  .small-button {
    display: inline-block;
      padding: 1px 30px;
      color: #FFFFFF;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer;
  }

  .button-gradient {
    display: inline-block;
      padding: 2px;
      border-radius: 10000px;
      background-image: -webkit-gradient(linear,left top,right top,from(#FF00FF),color-stop(49%,#AF74CF),to(#00FFFF));
      background-image: linear-gradient(90deg,#FF00FF,#AF74CF 49%,#00FFFF);
      box-shadow: 0 3px 7px 0 rgb(19 17 26 / 14%);
      -webkit-transform: scale3d(1,1,1.01);
      transform: scale3d(1,1,1.01);
      -webkit-transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s,-webkit-transform .3s;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
  }
</style>