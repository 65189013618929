// User Permissions
function isSuperAdmin( from, router ) {
  return router.app.$store.getters.userRole === 'ADMIN' ? true : false;
}

const superAdminAccess = ({ from, router, next }) => {
  if ( isSuperAdmin( from, router ) ) {
    return next();
  } else {
    router.push({ name: 'AccessDenied' });
  }
};

const adminAccess = async ({ from, router, next }) => {
  if ( isSuperAdmin( from, router ) ) {
    return next();
  }
  const permissions = await getPermissions(router);
  const isAllowed = permissions.filter( perm => perm.type === 'ADMIN' );
  if ( isAllowed.length === 0 ) {
    router.push({ name: 'AccessDenied' });
  }
  return next();
};

const contentManagerAccess = async ({ from, router, next }) => {
  if ( isSuperAdmin( from, router ) ) {
    return next();
  }
  const permissions = await getPermissions(router);
  const isAllowed = permissions.filter( perm => perm.type === 'ADMIN' || perm.type === 'CONTENT_MANAGER' );
  if ( isAllowed.length === 0 ) {
    router.push({ name: 'AccessDenied' });
  }
  return next();
};

const analyticsAccess = async ({ from, router, next }) => {
  if ( isSuperAdmin( from, router ) ) {
    return next();
  }
  const permissions = await getPermissions(router);
  const isAllowed = permissions.filter( perm => perm.type === 'ADMIN' || perm.type === 'CONTENT_MANAGER' || perm.type === 'ANALYTICS_MANAGER' );
  if ( isAllowed.length === 0 ) {
    router.push({ name: 'AccessDenied' });
  }
  return next();
};

const getPermissions = async (router) => {
  let permissions = router.app.$store.getters.permissions
  if (!permissions.length) {
    const userPermissions = await router.app.$store.dispatch('getAvailableOrgs');
    permissions = userPermissions?.viewer?.permissions || router.app.$store.getters.permissions || [];
  }
  return permissions
}

const getOrgFeatures = async (router) => {
  // return router.app.$store.getters.orgFeatures
  const orgFeatures = router.app.$store.getters.orgFeatures
  if (!Object.keys(orgFeatures).length) {
    await new Promise(resolve => setTimeout(resolve, 100)) // For the router and stage objects to load values
    const permissions = await getPermissions(router);
    const adminRoles = permissions.filter(perm => perm.type === 'ADMIN');
    const isAdmin = adminRoles?.length > 0;
    const orgId = this.$route.params.id || this.$route.params.orgId || router?.history?.current?.params?.id;
    const response = await router.app.$store.dispatch('selectOrganization', { orgId, admin: isAdmin, middleware: true });
    if (!response) {
      return {}
    }
    return router.app.$store.getters.orgFeatures
  } else {
    return router.app.$store.getters.orgFeatures
  }
}

const advertising = async ({ from, router, next }) => {
  if (isSuperAdmin(from, router)) {
    return next()
  }
  const orgFeatures = await getOrgFeatures(router) // To restrict based on org features
  if (!orgFeatures.ads) {
    router.push({ name: 'AccessDenied' })
  } else {
    await contentManagerAccess({ from, router, next})
  }
}

const analytics = async ({ from, router, next }) => {
  if (isSuperAdmin(from, router)) {
    return next()
  }
  const orgFeatures = await getOrgFeatures(router) // To restrict based on org features
  if (!orgFeatures.analytics) {
    router.push({ name: 'AccessDenied' })
  } else {
    await analyticsAccess({ from, router, next})
  }
}

const iv = async ({ from, router, next }) => {
  if (isSuperAdmin(from, router)) {
    return next()
  }
  const orgFeatures = await getOrgFeatures(router)
  if (!orgFeatures?.interactiveVideos) {
    router.push({ name: 'AccessDenied' })
  } else {
    await contentManagerAccess({ from, router, next})
  }
}

const transcodes = async ({ from, router, next }) => {
  if (isSuperAdmin(from, router)) {
    return next()
  }
  const orgFeatures = await getOrgFeatures(router) // To restrict based on org features
  if (!orgFeatures.transcoding) {
    router.push({ name: 'AccessDenied' })
  } else {
    await contentManagerAccess({ from, router, next})
  }
}

const pushNotifications = async ({ from, router, next }) => {
  if (isSuperAdmin(from, router)) {
    return next()
  }
  const orgFeatures = await getOrgFeatures(router) // To restrict based on org features
  if (!orgFeatures.pushNotifications) {
    router.push({ name: 'AccessDenied' })
  } else {
    await adminAccess({ from, router, next})
  }
}

export default {
  superAdminAccess,
  adminAccess,
  contentManagerAccess,
  analyticsAccess,
  advertising,
  analytics,
  iv,
  transcodes,
  pushNotifications
};
