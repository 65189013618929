<template>
  <div class="home">
    <img alt="logo" src="../assets/logo.png" />
    <p>Still under construction</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
  },
};
</script>
