import Vue from 'vue';
import { setUserToken, removeUserToken, removeUserExpToken, setUserExpToken } from '@/middleware/auth';
import {  } from '../../../middleware/auth';

// IMPORTANT: Use Vue.set() for reactive updates

const setUser = ( state, user ) => {
  if ( user ) {
    state.user = { ...state.user, ...user.user };
    if (user.token) {
      setUserToken( user.token );
    }
    if (user.tokenExpiryDate) {
      setUserExpToken( user.tokenExpiryDate );
    }
  } else {
    state.user = {};
    state.org = {};
    state.serverError = '';
    removeUserToken();
    removeUserExpToken();
  }
};

const setUserPermissions = ( state, user ) => {
  if ( user ) {
    Vue.set( state.user, 'permissions', user.permissions );
    Vue.set( state.user, 'organizations', user.organizations );
  }
}

const setOrganization = ( state, org) => {
  if ( org ) {
    if (state?.user?.organizations?.length) {
      state.user.organizations.forEach(organization => {
        if (organization.id === org.id) {
          if (org.logo?.url) {
            organization.logo = {
              url: org.logo.url
            }
          }
          organization.status = org.status;
          organization.name = org.name;
        }
      })
    }
    state.org = { ...state.org, ...org };
  } else {
    state.org = {};
  }
};

const selectApplication = ( state, app ) => {
  Vue.set( state.selected, 'application', app);
};

const setTranscodes = ( state, { transcodes, isNextPage } ) => {
  if ( transcodes ) {
    if ( isNextPage ) {
      transcodes = {
        ...transcodes,
        nodes: transcodes.nodes.length > 0 ? [ ...state.org.transcodes.nodes, ...transcodes.nodes ] : []
      }
    }
    Vue.set( state.org, 'transcodes', transcodes );
  } else {
    Vue.set( state.org, 'transcodes', null );
  }
};

const setPushNotifications = ( state, notifications ) => {
  if ( notifications ) {
    Vue.set( state.org, 'pushNotifications', notifications );
  } else {
    Vue.set( state.org, 'pushNotifications', null );
  }
};

const setInteractives = ( state, { interactives, isNextPage } ) => {
  if ( interactives ) {
    if ( isNextPage && state.org.interactives?.edges?.length && interactives) {
      interactives = {
        ...interactives,
        edges: [ ...state.org.interactives.edges, ...interactives.edges ]
      }
    }
    Vue.set( state.org, 'interactives', interactives );
  } else {
    Vue.set( state.org, 'interactives', {} );
  }
};

const setAds = ( state, { ads, isNextPage } ) => {
  if ( ads ) {
    if ( isNextPage ) {
      ads = {
        ...ads,
        nodes: ads.nodes.length > 0 ? [ ...state.org.ads.nodes, ...ads.nodes ] : []
      }
    }
    Vue.set( state.org, 'ads', ads );
  } else {
    Vue.set( state.org, 'ads', {} );
  }
};

const setOrgUsers = (state, { users, paginated }) => {
  if (state.org && users.length > 0 && !paginated) {
    Vue.set(state.org, 'users', users);
  } else if (state.org && users.length > 0 && paginated) {
    state.org.users.push(...users)
  } else {
    Vue.set(state.org, 'users', null);
  }
};

const setConnected = ( state, {reachable, errString = ''} ) => {
  state.connected = reachable;
  state.connectionError = errString;
};

const setError = ( state, {errString = ''} ) => {
  // state.serverError = (errString) ? errString : '';
  Vue.set( state, 'serverError', errString );
};

const setOrgSummary = ( state, orgSummary ) => {
  if ( orgSummary ) {
    Vue.set( state.org, 'dashboardOrgSummary', orgSummary );
  } else {
    Vue.set( state.org, 'dashboardOrgSummary', [] );
  }
};

const setMediaLibrarySummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'mediaLibrarySummary', data );
  } else {
    Vue.set( state.org, 'mediaLibrarySummary', [] );
  }
};

const setPublishingSummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'dashboardPublishingSummary', data );
  } else {
    Vue.set( state.org, 'dashboardPublishingSummary', [] );
  }
};

const setAdsSummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'dashboardAdsSummary', data );
  } else {
    Vue.set( state.org, 'dashboardAdsSummary', [] );
  }
}

const setUsersSummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'dashboardUsersSummary', data );
  } else {
    Vue.set( state.org, 'dashboardUsersSummary', [] );
  }
}

const setIvSummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'dashboardIvSummary', data );
  } else {
    Vue.set( state.org, 'dashboardIvSummary', [] );
  }
}

const setAppsSummary = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'dashboardAppsSummary', data );
  } else {
    Vue.set( state.org, 'dashboardAppsSummary', [] );
  }
};

const setProperties = ( state, { items, after } ) => {
  if ( items && !after ) {
    Vue.set( state.org, 'properties', items );
  } else if ( items && !!after ) {
    state.org.properties?.propertiesConnection?.nodes.push(...items?.propertiesConnection?.nodes);
    state.org.properties.propertiesConnection.pageInfo.endCursor = items?.propertiesConnection?.pageInfo?.endCursor;
    state.org.properties.propertiesConnection.pageInfo.hasNextPage = items?.propertiesConnection?.pageInfo?.hasNextPage;
  } else {
    Vue.set( state.org, 'properties', null );
  }
};

const setCollections = ( state, { items, after } ) => {
  if ( items && !after ) {
    Vue.set( state.org, 'collections', items );
  } else if ( items && !!after ) {
    state.org.collections.items.nodes.push(...items.items?.nodes);
    state.org.collections.items.pageInfo = items.items.pageInfo;
  } else {
    Vue.set( state.org, 'collections', null );
  }
};

const setContent = ( state,{ items, after } ) => {
  if ( items && !after ) {
    Vue.set( state.org, 'content', items );
  } 
  else if ( items && !!after && state.org?.content?.items?.nodes) {
    state.org.content.items.nodes.push(...items.items.nodes);
    state.org.content.items.pageInfo = items.items.pageInfo;
  } else {
    Vue.set( state.org, 'content', null );
  }
};

const setMediaLibraryItems = ( state, { items, after } ) => {
  if ( items && !after ) {
    Vue.set( state.org, 'mediaLibraryItems', items );
  } else if (items && !!after ) { 
    state.org.mediaLibraryItems.push(...items)
  } else {
    Vue.set( state.org, 'mediaLibraryItems', [] );
  }
}

const setMediaFolders = ( state, items ) => {
  if ( items ) {
    Vue.set( state.org, 'mediaFolders', items );
  } else {
    Vue.set( state.org, 'mediaFolders', [] );
  }
}

const setMediaLibraryCursor = ( state, { cursor, hasNextPage } ) => {
  if ( cursor ) {
    Vue.set( state.org, 'mediaLibraryCursor', cursor );
    Vue.set( state.org, 'hasNextPage', hasNextPage )
  } else {
    Vue.set( state.org, 'mediaLibraryCursor', null );
    Vue.set( state.org, 'hasNextPage', false )
  }
}

const setOrgApps = ( state, { apps, isNextPage } ) => {
  if ( apps ) {
    if ( isNextPage ) {
      apps = {
        ...apps,
        nodes: apps.nodes.length > 0 ? [ ...state.org.orgApps.nodes, ...apps.nodes ] : []
      }
    }
    Vue.set( state.org, 'orgApps', apps );
    Vue.set(state.org.orgApps, 'totalCount', state.org.orgApps?.nodes?.length || 0)
  } else {
    Vue.set( state.org, 'orgApps', {} );
  }
}

const setAppProperties = ( state, data ) => {
  if ( data ) {
    Vue.set( state.org, 'appProperties', data );
  } else {
    Vue.set( state.org, 'appProperties', [] );
  }
};

const setCurrentFolderNameAndParents = ( state, {folder,parents} ) => {
  if ( folder && parents ) {
    Vue.set( state.org, 'currentFolderAndParents', { folder: folder, parents: parents }  );
  } else {
    Vue.set( state.org, 'mediaLibraryCursor', null );
  }
}

const setMediaPickerItems = ( state, { items, after } ) => {
  if ( items && !after ) {
    Vue.set( state.org, 'mediaPickerItems', items.interfaceItems.edges );
    Vue.set( state.org, 'mediaPickerFolders', items.children );
  } else if (items && !!after ) { 
    state.org.mediaPickerItems.push(...items.interfaceItems.edges)
  } else {
    Vue.set( state.org, 'mediaPickerItems', [] );
  }
}

const setRootFolderID = ( state, { id } ) => {
  if( id ) {
    Vue.set( state.org, 'mediaLibraryRootFolderID', id );
  } else {
    Vue.set( state.org, 'mediaLibraryRootFolderID', '' );
  }
}

const setOrgCollections = ( state, items ) => {
  if ( items ) {
    Vue.set( state.org, 'orgCollections', items );
  } else {
    Vue.set( state.org, 'orgCollections', [] );
  }
}

const addOrganization = (state, org) => {
  if (state.user.organizations && state.user.organizations.length) {
    state.user.organizations.push(org);
  } else {
    Vue.set(state.user, 'organizations', [org]);
  }
}

const setMediaLibraryView = ( state, view ) => {
  if(  view ) {
    Vue.set( state.org, 'mediaLibraryView', view );
  } else {
    Vue.set( state.org, 'mediaLibraryView', 'tile' );
  }
}

const addUserToOrg = (state, user) => {
  if (state.org && (!state.org.users || !state.org.users.length)) {
    Vue.set(state.org, 'users', [user]);
  } else {
    state.org.users.push(user);
  }
  state.org.users.sort((a, b) => a.name <= b.name ? -1 : 1);
};

const setContentItem = ( state, contentItem ) => {
  if ( contentItem ) {
    Vue.set( state.org, 'contentItem', contentItem );
  } else {
    Vue.set( state.org, 'contentItem', {} );
  }
};

const updateAppTokensList = ( state, tokens ) => {
  if ( tokens ) {
    state.selected.application.tokens = tokens;
  }
}

const updateAppDevKeys = ( state, devKeys ) => {
  if ( devKeys ) {
    state.selected.application.allData = devKeys;
  }
}

const updateAppBreadcrumbName = ( state, app ) => {
  const apps = state.org?.orgApps?.nodes || [];
  if ( apps.length > 0 ) {
    apps.map( a => {
      if ( app.id === a.id ) {
        a.name = app.name;
      }
    } );
  } else {
    apps.push( app );
  }
  Vue.set( state.org, 'orgApps.nodes', apps);
};

const addApplicationAsset = (state, { asset }) => {
  if (state.org?.appAssets && state.org.appAssets.length) {
    state.org.appAssets.push(asset)
  } else {
    Vue.set(state.org, 'appAssets', [asset])
  }
}

const setApplicationAssets = (state, { applicationAssets, paginated }) => {
  if (applicationAssets && applicationAssets.length) {
    if (paginated && state.org?.appAssets && state.org.appAssets.length) {
      state.org.appAssets.push(...applicationAssets)
    } else {
      Vue.set(state.org, 'appAssets', applicationAssets)
    }
  } else {
    Vue.set(state.org, 'appAssets', [])
  }
}

const updateApplicationAsset = (state, { asset }) => {
  if (state.org?.appAssets) {
    state.org.appAssets.forEach(appAsset => {
      if (appAsset.id === asset.id) {
        Object.assign(appAsset, asset)
      }
    })
  }
}

const deleteApplicationAsset = (state, { assetId }) => {
  if (state.org?.appAssets) {
    state.org.appAssets = state.org.appAssets.filter(appAsset => appAsset.id !== assetId )
  }
}

const selectIV = ( state, iv ) => {
  Vue.set( state.selected, 'interactive', iv);
};

const updateSelectedIV = ( state, ivName ) => {
  state.selected.interactive.name = ivName;
}

const setAnalyticsData = ( state, data ) => {
  if ( state.org.analytics !== null ) {
    Vue.set( state.org, 'analytics', { ...state.org?.analytics, ...data } );
  } else {
    Vue.set( state.org, 'analytics', data );
  }
};

const setAnalyticsVisitorsDataTab = ( state, data ) => {
  if ( state.org.analyticsVisitorsDataTab !== null ) {
    Vue.set( state.org, 'analyticsVisitorsDataTab', { ...state.org?.analyticsVisitorsDataTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsVisitorsDataTab', data );
  }
};

const setAnalyticsTimeDataTab = ( state, data ) => {
  if ( state.org.analyticsTimeDataTab ) {
    Vue.set( state.org, 'analyticsTimeDataTab', { ...state?.org?.analyticsTimeDataTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsTimeDataTab', data );
  }
};

const setAnalyticsPlatformsTab = ( state, data ) => {
  if ( state.org.analyticsPlatformsTab ) {
    Vue.set( state.org, 'analyticsPlatformsTab', { ...state?.org?.analyticsPlatformsTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsPlatformsTab', data );
  }
};

const setAnalyticsPublishingTab = ( state, data ) => {
  if ( state.org.analyticsPublishingTab ) {
    Vue.set( state.org, 'analyticsPublishingTab', { ...state?.org?.analyticsPublishingTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsPublishingTab', data );
  }
};

const setAnalyticsCollectionsTab = ( state, data ) => {
  if ( state.org.analyticsCollectionsTab ) {
    Vue.set( state.org, 'analyticsCollectionsTab', { ...state?.org?.analyticsCollectionsTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsCollectionsTab', data );
  }
};

const setAnalyticsVodDataTab = ( state, data ) => {
  if ( state.org.analyticsVodDataTab ) {
    Vue.set( state.org, 'analyticsVodDataTab', { ...state.org.analyticsVodDataTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsVodDataTab', data );
  }
};

const setAnalyticsLiveStreamsTab = ( state, data ) => {
  if ( state.org.analyticsLiveStreamsTab ) {
    Vue.set( state.org, 'analyticsLiveStreamsTab', { ...state?.org?.analyticsLiveStreamsTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsLiveStreamsTab', data );
  }
};

const setAnalyticsUsagesTab = ( state, data ) => {
  if ( state.org.analyticsUsagesTab ) {
    Vue.set( state.org, 'analyticsUsagesTab', { ...state?.org?.analyticsUsagesTab, ...data } );
  } else {
    Vue.set( state.org, 'analyticsUsagesTab', data );
  }
};

const setViewer = (state, viewer) => {
  if (viewer) {
    Vue.set(state.org, 'viewer', viewer)
  } else {
    Vue.set(state.org, 'viewer', {})
  }
}

const setLockoutTime = ( state, data ) => {
  Vue.set( state, 'authLockout', data);
}
const setTheme = ( state, data ) => {
  Vue.set( state, 'theme', data);
}

const deleteApp = (state, appId) => {
  if (state.org?.orgApps?.nodes) {
    state.org.orgApps.nodes = state.org.orgApps.nodes.filter(app => app.id !== appId)
  }
}

export default {
  setUser,
  setUserPermissions,
  setConnected,
  setError,
  setOrganization,
  setTranscodes,
  setInteractives,
  setAds,
  setOrgUsers,
  setPushNotifications,
  selectApplication,
  setOrgSummary,
  setMediaLibrarySummary,
  setPublishingSummary,
  setAdsSummary,
  setAppsSummary,
  setProperties,
  setCollections,
  setContent,
  setMediaLibraryItems,
  setMediaFolders,
  setMediaLibraryCursor,
  setUsersSummary,
  setIvSummary,
  setOrgApps,
  setAppProperties,
  setCurrentFolderNameAndParents,
  setMediaPickerItems,
  setOrgCollections,
  updateAppTokensList,
  updateAppDevKeys,
  setMediaLibraryView,
  updateAppBreadcrumbName,
  addUserToOrg,
  addOrganization,
  setContentItem,
  addApplicationAsset,
  setApplicationAssets,
  updateApplicationAsset,
  deleteApplicationAsset,
  selectIV,
  updateSelectedIV,
  setRootFolderID,
  setAnalyticsData,
  setAnalyticsVisitorsDataTab,
  setAnalyticsTimeDataTab,
  setAnalyticsPlatformsTab,
  setAnalyticsPublishingTab,
  setAnalyticsCollectionsTab,
  setAnalyticsVodDataTab,
  setAnalyticsLiveStreamsTab,
  setAnalyticsUsagesTab,
  setViewer,
  setLockoutTime,
  deleteApp,
  setTheme
};
