import { HttpLink } from 'apollo-link-http';
import { makePromise, execute } from 'apollo-link';
import { backend } from '@/app.config';
import { getUserToken } from '@/middleware/auth';

// Client Connection to Venom Backend Server
const httpLink = new HttpLink({
  uri: backend.server,
  headers: {
    cms3: true
  }
});

const secureLink = (token) => {
  return new HttpLink({
    uri: backend.server,
    headers: {
      authorization: `Bearer ${token}` 
    }
  });
}

const isOperationNonAuth = ({ query }) => {
  const operations = ['login', 'signUp', 'requestResetPassword', 'resetCMSUserPassword', 'verifyCMSUserEmail']
  return operations.some((op) => query[op])
}

// Queries/mutations should use the HTTP link
export const venomExecute = async operation => {
  if ( operation.query ) {
    let opLink = httpLink;
    if (!isOperationNonAuth(operation)) {
      const token = getUserToken();
      opLink = secureLink(token); 
    }
    const res = await makePromise( execute( opLink, operation ) );
    if ( res.errors ) {
      if ( operation.query.login !== 'undefined' ) {
        return res;
      }
      throw new Error( res?.errors[0]?.message );
    }
    return res.data;
  }
  return 0;
};
