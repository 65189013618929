import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueClipboard from 'vue-clipboard2';
import VueToggles from 'vue-toggles';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import filters from './vue-filters';
import './registerServiceWorker';
import { theme, sentry } from './app.config';

// Vue Settings
Vue.config.productionTip = false;

// Vue Global Components
Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.component('VueToggles', VueToggles); 

// Import Global CSS
import "@/styles/base.scss";

// Main Vue Application
filters( Vue );

// Sentry
Sentry.init({
  Vue,
  dsn: sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: sentry.tracingOrigins
    }),
  ],
  denyUrls: sentry.denyUrls,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
  tracesSampleRate: sentry.tracesSampleRate
});

// Main Vue App
new Vue({
  router,
  store,
  i18n,
  created(){ 
    if ( Vue.$cookies.get('theme') ) {
      document.body.setAttribute('theme', Vue.$cookies.get('theme'));
    } else {
      document.body.setAttribute('theme', theme);
    }
  },
  render: (h) => h(App),
}).$mount("#app");
