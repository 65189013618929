// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
// https://www.currency-iso.org/en/home/tables/table-a1.html

export default {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'es-MX': {
    currency: {
      style: 'currency', currency: 'MXP', currencyDisplay: 'symbol'
    }
  },
  'de': {
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
    }
  },
  'es': {
    currency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
    }
  }
}
