import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '@/middleware/auth';
import log from '@/middleware/log';
import perm from '@/middleware/permissions';
import Home from '@/views/Home.vue';
import LandingPage from '@/views/LandingPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  // Empty Layout Views
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/verify-email',
    name: 'VerifyUserEmail',
    component: () => import('@/views/EmailVerification.vue')
  },
  {
    path: '/auth',
    component: () => import('@/layouts/EmptyLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
          middleware: [log]
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        redirect: { name: 'Login' }
      },
    ]
  },
  // Full Page Layout Views
  {
    path: '/user',
    component: () => import('@/layouts/FullPageLayout.vue'),
    children: [
      {
        path: 'organizations',
        name: 'Organizations',
        component: () => import('@/views/Organizations.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: 'organizations/new',
        name: 'OrganizationAdd',
        component: () => import('@/views/admin/OrganizationAdd.vue'),
        meta: {
          middleware: [auth, log, perm.superAdminAccess]
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('@/views/user/UserProfile.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: 'permissions',
        name: 'UserPermissions',
        component: () => import('@/views/user/UserPermissions.vue'),
        meta: {
          middleware: [auth, log]
        }
      }
    ]
  },
  // Admin Dashboard Layout Views
  {
    path: '/organizations',
    component: () => import('@/layouts/AdminLayout.vue'),
    children: [
      {
        path: ':id',
        name: 'Dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: ':id/mediaLibrary/bundle/:bundleId',
        name: 'MediaBundle',
        props: true,
        component: () => import('@/views/admin/mediaLibrary/MediaBundle.vue'),
        meta: {
          middleware: [auth, log, perm.contentManagerAccess]
        }
      },
      {
        path: ':id/mediaLibrary/media/:mediaId',
        name: 'MediaItem',
        props: true,
        component: () => import('@/views/admin/mediaLibrary/MediaItem.vue'),
        meta: {
          middleware: [auth, log, perm.contentManagerAccess]
        }
      },
      {
        path: ':id/mediaLibrary/:path*',
        name: 'MediaLibrary',
        props: true,
        component: () => import('@/views/admin/MediaLibrary.vue'),
        meta: {
          middleware: [auth, log, perm.contentManagerAccess]
        }
      },
      {
        path: ':id/publishing',
        name: 'Publishing',
        component: () => import('@/views/admin/Publishing.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: ':id/apps',
        name: 'Apps',
        component: () => import('@/views/admin/Apps.vue'),
        meta: {
          middleware: [auth, log, perm.adminAccess]
        }
      },
      {
        path: ':id/apps/:appId',
        redirect: { name: 'AppEdit' }
      },
      {
        path: ':id/apps/:appId/edit',
        name: 'AppEdit',
        component: () => import('@/views/admin/AppEdit.vue'),
        meta: {
          middleware: [auth, log, perm.adminAccess]
        }
      },
      {
        path: ':id/iv',
        name: 'InteractiveVideo',
        component: () => import('@/views/admin/InteractiveVideo.vue'),
        meta: {
          middleware: [auth, log, perm.iv]
        }
      },
      {
        path: ':id/iv/:ivId',
        name: 'InteractiveVideoDetail',
        component: () => import('@/views/admin/InteractiveVideoDetail.vue'),
        meta: {
          middleware: [auth, log, perm.iv]
        }
      },
      {
        path: ':id/advertising',
        name: 'Advertising',
        component: () => import('@/views/admin/Advertising.vue'),
        meta: {
          middleware: [auth, log, perm.advertising]
        }
      },
      {
        path: ':id/transcodes',
        name: 'Transcodes',
        component: () => import('@/views/admin/Transcodes.vue'),
        meta: {
          middleware: [auth, log, perm.transcodes]
        }
      },
      {
        path: ':id/analytics',
        name: 'Analytics',
        component: () => import('@/views/admin/Analytics.vue'),
        meta: {
          middleware: [auth, log, perm.analytics]
        }
      },
      {
        path: ':id/users',
        name: 'Users',
        component: () => import('@/views/admin/Users.vue'),
        meta: {
          middleware: [auth, log, perm.adminAccess]
        }
      },
      {
        path: ':id/users/new',
        name: 'InviteUser',
        component: () => import('@/views/user/InviteUser.vue'),
        meta: {
          middleware: [auth, log, perm.adminAccess]
        }
      },
      {
        path: ':id/users/:userId',
        name: 'OrgUserProfile',
        component: () => import('@/views/admin/OrgUserProfile.vue'),
        meta: {
          middleware: [auth, log, perm.adminAccess]
        }
      },
      {
        path: ':id/pushNotifications',
        name: 'PushNotifications',
        component: () => import('@/views/admin/PushNotifications.vue'),
        meta: {
          middleware: [auth, log, perm.pushNotifications]
        }
      },
      {
        path: ':id/documentation',
        name: 'Documentation',
        redirect: '/docs/index.html'
      },
      {
        path: ':id/edit',
        name: 'OrganizationEdit',
        component: () => import('@/views/admin/OrganizationEdit.vue'),
        meta: {
          middleware: [auth, log, perm.superAdminAccess]
        }
      },
      {
        path: '/organizations/:id/publishing/:propertyId/:propertyName/content',
        name: 'Collections',
        props: true,
        component: () => import('@/views/admin/Collections.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: '/organizations/:id/publishing/:propertyId/collection/:collectionId',
        name: 'Content',
        props: true,
        component: () => import('@/views/admin/Content.vue'),
        meta: {
          middleware: [auth, log]
        }
      },
      {
        path: '/organizations/:id/publishing/:propertyId/content/:contentId',
        name: 'ContentDetails',
        props: true,
        component: () => import('@/views/admin/content/ContentDetails.vue'),
        meta: {
          middleware: [auth, log]
        }
      }
    ]
  },
  // Access Control
  {
    path: '/restricted',
    name: 'AccessDenied',
    component: () => import('@/views/AccessDenied.vue'),
    meta: {
      middleware: [log]
    }
  },
  // Catch-all
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      middleware: [log]
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach( async(to, from, next) => {
  if ( !from.name ) {
    // Insert this wait timer so veux state can be loaded
    await new Promise( r => setTimeout(r, 300) ); 
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }

});

export default router;
