// Helper Functions
const comparePushStart = ( a, b ) => {
  if ( a.createdAt > b.createdAt ) return -1;  
  if ( a.createdAt > b.scheduledFor ) return -1;
  if ( a.scheduledFor > b.scheduledFor ) return -1;
  if ( a.scheduledFor > b.createdAt ) return -1;

  if ( b.createdAt > a.createdAt ) return 1;  
  if ( b.createdAt > a.scheduledFor ) return 1;
  if ( b.scheduledFor > a.scheduledFor ) return 1;
  if ( b.scheduledFor > a.createdAt ) return 1;
  return 0;
};

// Getters 
const propertiesCount = (state) => {
  if (state.user.organizations) { 
    return state.user.organizations.reduce( (acc, org) => acc += org.propertiesConnection.totalCount, 0 );
  }
  return 0;
};

const organizations = (state) => {
  return (state.user.organizations) ? state.user.organizations : [];
}

const allPushNotifications = (state) => {
  const pushes = state.org.pushNotifications;
  if (pushes) {
    return pushes.reduce( (acc, pn) => {
      const c = [...pn.internalPushNotifications, ...pn.internalScheduledNotifications];
      acc.push(c);
      return acc;
    }, []).reduce( (acc, pn) => acc = [...acc, ...pn], [] ).sort( comparePushStart );
  } 
  return [];
}

const orgId = (state) => {
  return (state.org.id) ? state.org.id : null;
}

const rootFolderId = ( state ) => {
  return (state.org.mediaLibrary?.root?.id) ? state.org.mediaLibrary.root.id : null
}

const userRole = (state) => {
  return state?.org?.viewer?.role || state.user.role
  // return (state.user.role) ? state.user.role : null;
}

const permissions = (state) => {
  return (state.org?.viewer?.permissions) ? state.org?.viewer?.permissions : state.user.permissions || []
}

const orgFeatures = (state) => {
  return state.org?.options?.featureSupport || {}
}

export default {
  propertiesCount,
  organizations,
  permissions,
  userRole,
  allPushNotifications,
  orgId,
  rootFolderId,
  orgFeatures
}
