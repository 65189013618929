import { venomExecute } from './connection';
import { 
  LOGIN_USER, 
  GET_ORGANIZATION, 
  GET_TRANSCODES, 
  GET_PUSH_NOTIFICATIONS, 
  GET_INTERACTIVES, 
  GET_ADS, 
  GET_APPLICATION, 
  GET_AVAILABLE_ORGS,
  GET_USERS, 
  GET_USER_DETAILS, 
  SET_PROPERTY_PERMISSION, 
  REMOVE_USER_FROM_ORG, 
  POST_RETRANSCODE, 
  UPDATE_USERINFO,
  GET_DASHBOARD_ORG_SUMMARY,
  GET_DASHBOARD_MEDIA_LIBRARY_SUMMARY,
  GET_DASHBOARD_PUBLISHING_SUMMARY,
  GET_DASHBOARD_ADS_SUMMARY,
  GET_DASHBOARD_APPS_SUMMARY,
  GET_PROPERTIES,
  GET_COLLECTIONS,
  GET_CONTENT,
  GET_MEDIA_LIBRARY_ITEMS,
  GET_DASHBOARD_USERS_SUMMARY,
  GET_DASHBOARD_IV_SUMMARY,
  DELETE_PROPERTY,
  SET_CONTENT_POSITION,
  UPDATE_USER_PASSWORD,
  GET_ORG_APPS,
  GET_APP_PROPERTIES,
  CREATE_MEDIA,
  GET_AWS_CONFIG,
  SIGN_REQUEST,
  CREATE_MEDIA_BUNDLE,
  CREATE_NEW_APP,
  REGISTER_CMS_USER,
  DELETE_MEDIA_BUNDLE,
  DELETE_MEDIA,
  DELETE_MEDIA_FOLDER,
  GET_MEDIA_LIBRARY_FOLDER_ITEMS,
  UPDATE_MEDIA_FOLDER,
  UPDATE_MEDIA,
  UPDATE_MEDIA_BUNDLE,
  UPDATE_COLLECTION,
  DELETE_COLLECTION,
  DELETE_CONTENT,
  GET_ORG_COLLECTIONS,
  UPDATE_CONTENT,
  COPY_CONTENT_DEEP,
  GET_MEDIA_BUNDLE,
  DELETE_APPLICATION,
  UPDATE_APPLICATION,
  GENERATE_APPLICATION_TOKEN,
  SET_APPLICATION_DEV_KEYS,
  CREATE_NEW_FOLDER,
  CREATE_PROPERTY,
  CREATE_COLLECTION,
  TRANSCODE_MEDIA,
  PROBE_MEDIA,
  GET_MEDIA,
  UPDATE_PROPERTY,
  INVITE_USER,
  EDIT_ORG,
  CREATE_ORG,
  DELETE_AD,
  GET_CONTENT_ITEM,
  CREATE_STREAM,
  CREATE_VOD,
  GET_APPLICATION_ASSETS,
  CREATE_APPLICATION_ASSET,
  UPDATE_APPLICATION_ASSET,
  CREATE_AD_CONTENT,
  GET_MEDIA_LIBRARY_SEARCH_ITEMS,
  GET_MEDIA_LIBRARY_FOLDER_SEARCH_ITEMS,
  DELETE_APPLICATION_ASSET,
  GET_HTML_APPLICATION_TOKEN,
  CREATE_INTERACTIVE,
  GET_INTERACTIVE,
  UPDATE_INTERACTIVE,
  DELETE_INTERACTIVE,
  CREATE_INTERACTIVE_CONTENT,
  SET_CONTENT_LANGUAGE,
  DELETE_CONTENT_LANGUAGE,
  CREATE_AD,
  UPDATE_AD,
  SEND_NOTIFICATION_TO_AN_APP,
  DELETE_CONTENT_CAMERA,
  UPDATE_CONTENT_CAMERA,
  CREATE_CONTENT_CAMERA,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  UPDATE_CONTENT_ART,
  DELETE_CONTENT_ART,
  DELETE_NOTIFICATION,
  GET_AD,
  GET_ORG_PERMISSIONS,
  CREATE_VOD_FORMAT,
  UPDATE_VOD_FORMAT,
  DELETE_VOD_FORMAT,
  UPDATE_VOD_SETTINGS,
  SET_MEDIA_DATA,
  ANALYTICS_UNIQUE_AND_ACTIVE_USERS,
  ANALYTICS_AVG_VIEW_AND_APP_TIME,
  ANALYTICS_POPULAR_PLATFORMS,
  ANALYTICS_POPULAR_CATEGORIES,
  ANALYTICS_POPULAR_COLLECTIONS,
  ANALYTICS_VOD_UNIQUE_VIEWS,
  ANALYTICS_UNIQUE_COMPLETED_VODS,
  ANALYTICS_TOP_LIVE_STREAMS,
  ANALYTICS_TOP_STORIES,
  ANALYTICS_VIDEO_DOWNLOADS,
  ANALYTICS_UNIQUE_VIEWS,
  ANALYTICS_LIVE_STREAMS,
  ANALYTICS_USAGES_DATA,
  ANALYTICS_XR_ACTIVE_USERS_COUNT,
  ANALYTICS_API_RESPONSE_TIMES,
  ANALYTICS_TOP_COUNTRIES,
  ANALYTICS_ALL_PLATFORMS,
  VERIFY_USER_EMAIL,
  GET_MEDIA_ITEM_STATUS,
  RESEND_ORG_INVITE,
  CREATE_AR_CONTENT,
  UPDATE_AR_CONTENT
} from './graphql-api';

const handleError = async(commit, err, showModal = true) => {
  if(showModal)
    commit( 'setError', { errString: err.message } );
  return err.message;
}

const logoutUser = async({ commit }) => {
  commit('setUser');
};

const clearError = async({ commit }) => {
  commit('setError', { errString: '' });
};

const getAvailableOrgs = async({ commit }) => {
  const response = await venomExecute({
    query: GET_AVAILABLE_ORGS,
    variables: { propertyLimit: 100 }
  });
  if ( response.viewer.organizations.length > 0 ) {
    commit( 'setUserPermissions', response.viewer );
  }
  return response
};

const getViewer = async ({ commit }) => {
  const response = await venomExecute({
    query: GET_AVAILABLE_ORGS,
    variables: { propertyLimit: 100 }
  })
  if ( response?.viewer?.organizations.length > 0 ) {
    commit( 'setUserPermissions', response.viewer );
    const { viewer } = response
    const user = { 
      active: viewer.active,
      email: viewer.email,
      screenName: viewer.screenName,
      id: viewer.id,
      role: viewer.role,
      lastLogin: viewer.lastLogin,
      firstName: viewer.firstName,
      lastName: viewer.lastName,
      registered: viewer.registered
    }
    commit( 'setUser', { user } );
    commit( 'setConnected', { reachable: true } );
  }
  return response?.viewer
}

const loginUser = async({ commit }, { email, password }) => {
  const response = await venomExecute({
    query: LOGIN_USER,
    variables: { email, password }
  });
  if ( response.errors ) {
    return { status: false, error: response.errors[0].message || 'Unknown error' };
  } else if ( response.authenticateCMSUser.user.email ) {
    commit( 'setUser', response.authenticateCMSUser );
    commit( 'setConnected', { reachable: true } );
    await getAvailableOrgs({ commit });
    return { status: true, response }
  } else {
    throw new Error( 'Authorization error: invalid account' );
  }
};

const updateUserInfo = async({ state, commit }, { firstName, lastName, email }) => {
  try {
    const user = state.user.id; 
    const response = await venomExecute({
      query: UPDATE_USERINFO,
      variables: { input: { firstName, lastName, user, email } }
    });
    if ( response.updateCMSUserInfo?.user ) {
      commit( 'setUser', response.updateCMSUserInfo );
      commit( 'setConnected', { reachable: true } );
      return response.updateCMSUserInfo.user;
    } else {
      throw new Error( 'Authorization error: invalid account' );
    }
  } catch (err) {
    return await handleError( commit, err );
  }
};

const clearOrganization = async({ commit }) => {
  commit('setOrganization');
};

const selectOrganization = async({ commit }, { orgId, admin, middleware }) => {
  try {
    const response = await venomExecute({
      query: GET_ORGANIZATION,
      variables: { orgId, admin }
    });
    if (!middleware && response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setOrganization', response.organization );
    return response.organization;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const selectApplication = async({ commit }, { appId }) => {
  try {
    const response = await venomExecute({
      query: GET_APPLICATION,
      variables: { appId, first: 50 }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'selectApplication', response.application );
    return response.application;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchTranscodes = async({ commit }, { orgId, onlyTranscodeStatuses, first = 20, after = null, sort = "transcodeInfo.startedAt", sortDirection = "DESC", isNextPage = false }) => {
  try {
    const response = await venomExecute({
      query: GET_TRANSCODES,
      variables: { 
        orgId, 
        first,
        after,
        onlyTranscodeStatuses,
        sort,
        sortDirection
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setTranscodes', { transcodes: response.organization?.mediaLibrary?.search || {}, isNextPage } );
    return response.organization?.mediaLibrary?.search ;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const reTranscode = async({ commit }, { orgId, mediaId }) => {
  try {
    const response = await venomExecute({
      query: POST_RETRANSCODE,
      variables: { 
        input: { media: mediaId }, 
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    await fetchTranscodes({ commit }, { orgId });
    return response.reTranscodeMedia.pending;
  } catch (err) {
    return await handleError( commit, err );
  }
} 

const fetchPushNotifications = async({ commit }, { orgId }) => {
  try {
    const response = await venomExecute({
      query: GET_PUSH_NOTIFICATIONS,
      variables: { 
        orgId, 
        first: 500,
        sortDirection: "DESC" 
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response )
      commit( 'setPushNotifications', response.organization?.applicationsConnection?.nodes  || []);
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchInteractives = async({ commit }, { orgId, first = 20, after = '', isNextPage = false }) => {
  try {
    const response = await venomExecute({
      query: GET_INTERACTIVES,
      variables: { 
        orgId, 
        first,
        after
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response ) {
      commit( 'setInteractives', { interactives: response.organization?.interactives || {}, isNextPage } );
      return response?.organization?.interactives;
    }
  } catch (err) {
    return await handleError( commit, err );
  }
};

const clearInteractives = async ({ commit }) => {
  commit('setInteractives', {})
}

const fetchAds = async({ commit }, { orgId, first = 10, after = '', isNextPage = false, adType = '' }) => {
  try {
    const response = await venomExecute({
      query: GET_ADS,
      variables: {
        orgId, 
        first,
        after,
        adType
      }
    });
    if ( response )
      commit( 'setAds', { ads: response.organization?.adsConnection || {}, isNextPage } );
    return response
  } catch (err) {
    return await handleError( commit, err );
  }
}

const fetchUsers = async ({ commit }, { orgId }) => {
  try {
    const params = {
      query: GET_USERS,
      variables: {
        orgId,
        first: 75,
        after: null
      }
    }
    let response = await venomExecute(params);

    if (response.errors) {
      throw new Error('Server error: ', response.errors[0]);
    }

    commit('setOrgUsers', { users: response?.organization?.usersConnection?.nodes });

    while (response?.organization?.usersConnection?.pageInfo?.hasNextPage) {
      params.variables.after = response.organization.usersConnection.pageInfo.endCursor
      response = await venomExecute(params);

      if (response.errors) {
        throw new Error('Server error: ', response.errors[0]);
      }

      commit('setOrgUsers', { users: response?.organization?.usersConnection?.nodes, paginated: true });
    }
  } catch (err) {
    return await handleError(commit, err);
  }
};

const fetchAllOrgApps = async ({ commit }, { orgId }) => {
  try {
    const params = {
      query: GET_ORG_APPS,
      variables: {
        orgId,
        fetchFirst: 50,
        after: ''
      }
    }
    let response = await venomExecute(params);
    if (response.errors) {
      throw new Error('Server error: ', response.errors[0]);
    }
    commit('setOrgApps', { apps: response.organization?.applicationsConnection || {}, isNextPage: false });
    while (response.organization.applicationsConnection?.pageInfo.hasNextPage) {
      params.variables.after = response.organization.applicationsConnection.pageInfo.endCursor;
      response = await venomExecute(params)
      commit('setOrgApps', { apps: response.organization?.applicationsConnection || {}, isNextPage: true });
    }
    return response.organization;
  } catch (err) {
    return await handleError(commit, err);
  }
};

const removeUserFromOrg = async({ commit }, { orgId, userId }) => {
  try {
    const response = await venomExecute({
      query: REMOVE_USER_FROM_ORG,
      variables: { 
        input: {
          organization: orgId,
          user: userId
        }
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    await fetchUsers({ commit }, { orgId });
    return response.removeCMSUserFromOrg.organization.usersConnection.totalCount;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const setUserDetails = async ({ commit }, { orgId, userId }) => {
  const response = await fetchUserDetails({ commit }, { orgId, userId })
  if (response.user) {
    commit('setUser', response)
  }
  return response
}

const fetchUserDetails = async({ commit }, { orgId, userId }) => {
  try {
    const response = await venomExecute({
      query: GET_USER_DETAILS,
      variables: { 
        orgId,
        userId
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.organization;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const setPropertyPermission = async({ commit }, { orgId, userId, propertyId, type }) => {
  try {
    const response = await venomExecute({
      query: SET_PROPERTY_PERMISSION,
      variables: {
        orgId,
        input: {
          property: propertyId,
          type,
          user: userId
        }
      }
    }); 
    if (response.errors) {
      throw new Error('Server error: ' + response?.errors[0]?.message || response.errors[0]);
    }
    return response.setPropertyPermission.user;
  } catch (err) {
    if (err && err.message.includes('Insufficient')) {
      return err.message
    } else {
      return await handleError(commit, err);
    }
  }
};

/* Dashboard actions */

const fetchOrgSummary = async({ commit }, { orgId, admin, contentManager, fetchFirst = 100, analyticsEnabled, ivEnabled }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_ORG_SUMMARY,
      variables: { 
        orgId, 
        admin,
        contentManager,
        fetchFirst,
        analyticsEnabled,
        ivEnabled
      }
    }); 
    if ( response ) {
      commit( 'setOrgSummary', response.organization );
      return response.organization;
   }
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchMediaLibrarySummary = async({ commit }, { orgId }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_MEDIA_LIBRARY_SUMMARY,
      variables: { 
        orgId
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setMediaLibrarySummary', response.organization?.mediaLibrary );
    return response.organization;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchAppsSummary = async({ commit }, { orgId, admin, fetchFirst = 100 }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_APPS_SUMMARY,
      variables: { 
        orgId,
        admin,
        fetchFirst
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAppsSummary', response.organization?.applicationsConnection?.nodes || [] );
    return response.organization;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchAdsSummary = async({ commit }, { orgId, contentManager, fetchFirst = 100 }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_ADS_SUMMARY,
      variables: { 
        orgId,
        contentManager,
        fetchFirst
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAdsSummary', response.organization?.adsConnection?.nodes || [] );
    return response.organization;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const fetchUsersSummary = async({ commit }, { orgId, admin, fetchFirst = 100, sort = 'firstName' }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_USERS_SUMMARY,
      variables: { 
        orgId,
        admin,
        fetchFirst,
        sort
      }
    }); 
    if ( response ) {
      commit( 'setUsersSummary', response.organization?.usersConnection?.nodes || [] );
      return response.organization;
    }
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const fetchPublishingSummary = async({ commit }, { orgId, fetchFirst = 100, sort = 'name' }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_PUBLISHING_SUMMARY,
      variables: { 
        orgId,
        fetchFirst,
        sort
      }
    });
    commit( 'setPublishingSummary', response?.organization?.propertiesConnection?.nodes || [] );
    return response.organization;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const fetchIvSummary = async({ commit }, { orgId, fetchFirst = 100 }) => {
  try {
    const response = await venomExecute({
      query: GET_DASHBOARD_IV_SUMMARY,
      variables: { 
        orgId,
        fetchFirst
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response ) {
      commit( 'setIvSummary', response.organization?.interactives?.nodes || [] );
      return response.organization;
    }
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const fetchProperties = async({ commit }, { orgId, sort, after }) => {
  try {
    const params = {
      query: GET_PROPERTIES,
      variables: {
        orgId,
        sort,
        first: 75,
        after
      }
    }
    let response = await venomExecute(params);
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit('setProperties', { items: response.organization, after:after });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const fetchMediaLibraryItems = async( {commit}, {orgId,mediaClass,type,filter, after, modal, sort, sortDirection } ) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_LIBRARY_ITEMS,
      variables: {
        organizationId: orgId,
        mediaClass: mediaClass,
        after: after,
        type: type,
        filter: filter,
        sort,
        sortDirection

      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if( !modal ) {
      commit( 'setMediaLibraryItems', {items: response.organization.mediaLibrary.folder.interfaceItems.edges, after } );
      commit( 'setMediaFolders', response.organization.mediaLibrary.folder.children );
      const pageInfo = response.organization.mediaLibrary.folder.interfaceItems.pageInfo;
      commit( 'setCurrentFolderNameAndParents',{ folder: response.organization.mediaLibrary.folder, parents: response.organization.mediaLibrary.folder.parents} );      
      commit( 'setMediaLibraryCursor', { cursor: pageInfo.endCursor, hasNextPage: pageInfo.hasNextPage } );
      commit( 'setRootFolderID', { id: response.organization.mediaLibrary.folder.id } )
      return response.organization.mediaLibrary.folder;
    } else {
      commit('setMediaPickerItems', {items: response.organization.mediaLibrary.folder, after });
      return response.organization.mediaLibrary.folder.interfaceItems.pageInfo;
    }
  } catch (err) {
    return await handleError( commit, err );
  }
}

const fetchCollections = async({ commit }, { propertyId, after }) => {
  try {
    const response = await venomExecute({
      query: GET_COLLECTIONS,
      variables: {
        propertyId,
        after,
        first: 10
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setCollections', { items: response.property, after });
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchContent = async({ commit }, { collectionId, after, first=10 }) => {
  try {
    const response = await venomExecute({
      query: GET_CONTENT,
      variables: {
        collectionId,
        after,
        first
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setContent', { items: response.collection, after });
    return response.collection;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteProperty = async({ commit }, { propertyId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_PROPERTY,
      variables: { propertyId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const setContentPosition = async({ commit }, { variables, propertyId, collectionId }) => {
  try {
    const response = await venomExecute({
      query: SET_CONTENT_POSITION,
      variables
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( propertyId )
      await fetchCollections({ commit }, { propertyId });
    if ( collectionId )
      await fetchContent({ commit }, { collectionId, first: 999 });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateUserPassword = async ({ state, commit }, { password }) => {
  try {
    const user = state.user.id
    const response = await venomExecute({
      query: UPDATE_USER_PASSWORD,
      variables: { input: { user, password } }
    })
    if (response.updateCMSUserInfo.user) {
      commit('setUser', response.updateCMSUserInfo)
      commit('setConnected', { reachable: true })
      return response.updateCMSUserInfo.user
    } else {
      throw new Error('Authorization error: invalid account')
    }
  } catch (err) {
    return await handleError(commit, err)
  }
}

const getAppProperties = async({ commit }, { orgId }) => {
  try {
    const response = await venomExecute({
      query: GET_APP_PROPERTIES,
      variables: { 
        orgId
      }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response.organization.properties ) {
      const applicationProperties = response.organization.properties;
      if( applicationProperties.length > 0) {
        applicationProperties.sort(function (a, b) {
            let x = a.i18nName.localized.value.toUpperCase(),
                y = b.i18nName.localized.value.toUpperCase();
            return x == y ? 0 : x > y ? 1 : -1;
        });
        commit( 'setAppProperties', applicationProperties );
      }
    }
    return response.organization;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const createMedia = async( {commit}, variables ) => {
  try {
    const response = await venomExecute({
      query: CREATE_MEDIA,
      variables: { input: variables }
    });
    return response.createMedia.media;
  } catch (err) {
    handleError(commit,err,false);
    throw new Error( 'Server error: ', err );
  }
}

const getAWSConfig = async({commit}, { fileName, expectedMediaClass }) => {
  try {
    const response = await venomExecute({
      query: GET_AWS_CONFIG,
      variables: { input: { fileName, expectedMediaClass } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const signRequest = async({commit}, { toSign }) => {
  try {
    const response = await venomExecute({
      query: SIGN_REQUEST,
      variables: { stringToSign: toSign  }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.signS3PutRequest?.signedRequest;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createMediaBundle = async({commit}, { mediaId }) => {
  try {
    const response = await venomExecute({
      query: CREATE_MEDIA_BUNDLE,
      variables: { input: { media: { id: mediaId } } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
  } catch (err) {
    return await handleError( commit, err );
  }
}
const updateCollection = async({ commit }, { variables, propertyId, collectionId }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_COLLECTION,
      variables: { input: variables }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( propertyId ) {
      await fetchCollections({ commit }, { propertyId });
    }
    if ( collectionId ) {
      await fetchContent({ commit }, { collectionId, first: 999 });
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteCollection = async({ commit }, { collectionId, propertyId, collection }) => {
  try {
    const response = await venomExecute({
      query: DELETE_COLLECTION,
      variables: { input: { collection: collectionId } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( propertyId ) {
      await fetchCollections({ commit }, { propertyId });
    }
    if ( collection ) {
      await fetchContent({ commit }, { collectionId: collection, first: 999 });
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateContent = async({ commit }, { contentId, collectionId, collection, input }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_CONTENT,
      variables: { input: input ? input : { content: contentId, moveTo: collectionId } }
    });
    if ( input )
      await getContentItem({ commit }, { contentId: input.content });
    else
      await fetchContent({ commit }, { collectionId: collection, first: 999 });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const copyContentDeep = async({ commit }, { contentId, collection }) => {
  try {
    const response = await venomExecute({
      query: COPY_CONTENT_DEEP,
      variables: { input: { content: contentId, collection } }
    });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const deleteContent = async({ commit }, { contentId, collectionId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_CONTENT,
      variables: { input: { content: contentId } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    await fetchContent({ commit }, { collectionId, first: 999 });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchOrgCollections = async({ commit }, { orgId }) => {
  try {
    const response = await venomExecute({
      query: GET_ORG_COLLECTIONS,
      variables: { orgId: orgId, first: 999 }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.organization?.search?.nodes;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const saveOrgNewApp = async({ commit }, { appData }) => {
  try {
    const response = await venomExecute({
      query: CREATE_NEW_APP,
      variables: { input: appData }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.createApplication?.application?.id || false;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const registerCMSUser = async ({ commit }, cmsUser) => {
  try {
    const response = await venomExecute({
      query: REGISTER_CMS_USER,
      variables: { input: cmsUser }
    })
    if ( response.errors ) {
      if (response?.errors?.length && response?.errors[0]?.message?.includes('already exists')) {
        return response.errors[0].message
      } else {
        throw new Error( 'Server error: ' + response.errors[0]?.message || response.errors[0] );
      }
    }
    return response?.registerCMSUser
  } catch (err) {
    return await handleError( commit, err );
  }
}

const deleteMedia = async({ commit }, { mediaId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_MEDIA,
      variables: { input: { media: mediaId } }
    });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const deleteMediaBundle = async({ commit }, { mediaId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_MEDIA_BUNDLE,
      variables: { input: { bundle: mediaId } }
    });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteMediaFolder = async({ commit }, { folderId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_MEDIA_FOLDER,
      variables: { input: { folder: folderId } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchMediaLibraryFolderItems = async( {commit}, { orgId,mediaClass,type,folderId, after, modal, sort, sortDirection, filter = false } ) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_LIBRARY_FOLDER_ITEMS,
      variables: {
        organizationId: orgId,
        folderId,
        mediaClass: mediaClass,
        after: after,
        type: type,
        filter: filter,
        sort,
        sortDirection
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if( !modal ) {
      commit( 'setMediaLibraryItems', {items: response.organization.mediaLibrary.folder.interfaceItems.edges, after } );
      commit( 'setMediaFolders', response.organization.mediaLibrary.folder.children );
      commit( 'setCurrentFolderNameAndParents',{ folder: response.organization.mediaLibrary.folder, parents: response.organization.mediaLibrary.folder.parents} );      
      const pageInfo = response.organization.mediaLibrary.folder.interfaceItems.pageInfo
      commit( 'setMediaLibraryCursor', { cursor: pageInfo.endCursor, hasNextPage: pageInfo.hasNextPage } );
      return response.organization.mediaLibrary.folder;
    } else {
      commit('setMediaPickerItems', {items: response.organization.mediaLibrary.folder, after });
      commit( 'setCurrentFolderNameAndParents',{ folder: response.organization.mediaLibrary.folder, parents: response.organization.mediaLibrary.folder.parents} );      return response.organization.mediaLibrary.folder.interfaceItems.pageInfo;
    }
  } catch (err) {
    return await handleError( commit, err );
  }
}

const updateMediaFolder = async({ commit }, { folderId, moveTo }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_MEDIA_FOLDER,
      variables: { input: { folder: folderId, moveTo } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateMediaBundle = async({ commit }, { variables }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_MEDIA_BUNDLE,
      variables: { input: variables }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateMedia = async({ commit }, { variables }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_MEDIA,
      variables: { input: variables }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createFolder = async({commit},{name,parent}) => {
  try {
    const response = await venomExecute({
      query: CREATE_NEW_FOLDER,
      variables: { input: { name, parent } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.createMediaFolder?.folder?.id;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const createProperty = async({ commit }, { name, headline, description, keyArt, organization }) => {
  try {
    const response = await venomExecute({
      query: CREATE_PROPERTY,
      variables: { input: { name, headline, description, keyArt, organization } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createCollection = async({ commit }, { name, description, staging, type, keyArt, property, collection }) => {
  try {
    const response = await venomExecute({
      query: CREATE_COLLECTION,
      variables: { input: property ? { name, description, keyArt, staging, type, property } : { name, description, keyArt, staging, type, collection }}
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( property )
      await fetchCollections({ commit }, { propertyId: property });
    if ( collection )
      await fetchContent({ commit }, { collectionId: collection, first: 999 });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const fetchMediaBundle = async ({ commit }, { bundleId }) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_BUNDLE,
      variables: { mediaBundleId: bundleId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.mediaBundle;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const fetchMediaItemStatus = async ({ commit }, { bundleId }) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_ITEM_STATUS,
      variables: { mediaBundleId: bundleId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.mediaBundle;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const transcodeMedia = async ({ commit }, { media, targetFormat, clearvrEncodeQuality }) => {
  try {
    const response = await venomExecute({
      query: TRANSCODE_MEDIA,
      variables: { input: { media, targetFormat, clearvrEncodeQuality } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const reprobeMedia = async ({ commit }, { media }) => {
  try {
    const response = await venomExecute({
      query: PROBE_MEDIA,
      variables: { input: { media } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const fetchMedia = async ({ commit }, { mediaId }) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA,
      variables: { mediaId  }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.media;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const updateProperty = async({ commit }, { i18nName, i18nHeadline, i18nDescription, keyArt, property, organization, isProperty }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_PROPERTY,
      variables: { input: { i18nName, i18nHeadline, i18nDescription, keyArt, property } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( !isProperty )
      await fetchCollections({ commit }, { propertyId: property });
    else 
      await fetchProperties({ commit }, { orgId: organization, sort: 'name' });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const inviteUser = async ({ commit }, { orgId, email }) => {
  try {
    const response = await venomExecute({
      query: INVITE_USER,
      variables: { orgId, email  }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if (response?.inviteCMSUserToOrganization?.cmsUser) {
      const userId = response?.inviteCMSUserToOrganization?.cmsUser?.id;
      const newUser = await fetchUserDetails({ commit }, { orgId, userId  });
      commit('addUserToOrg',newUser?.user);
    }
    return response?.inviteCMSUserToOrganization;
  } catch (err) {
      return await handleError( commit, err );
  }
}

const editOrg = async ({ commit }, orgData) => {
  try {
    const previewUrl = orgData.previewUrl;
    delete orgData.previewUrl;
    const response = await venomExecute({
      query: EDIT_ORG,
      variables: { input: orgData }
    })
    if (response.updateOrganization.organization) {
      if (previewUrl) {
        response.updateOrganization.organization.logo.url= previewUrl;
      }
      commit( 'setOrganization', response.updateOrganization.organization);
    }
    return response.updateOrganization?.organization;
  } catch (err) {
    return await handleError(commit, err);
  }
}

const createOrg = async ({ commit }, orgData) => {
  try {
    const response = await venomExecute({
      query: CREATE_ORG,
      variables: { input: orgData }
    })
    if (response.createOrganization?.organization) {
      commit( 'addOrganization', response.createOrganization?.organization );
    }
    return response.createOrganization?.organization;
  } catch (err) {
    return await handleError(commit, err);
  }
}

const deleteAd = async({ commit }, { adId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_AD,
      variables: { input: { ad: adId } }
    });
    if ( response.errors ) {
      if (response.errors[0].message?.includes('still in use')) {
        throw new Error(response.errors[0].message)
      }
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  }
  catch (err) {
    if (err.message.includes('still in use'))
      return err
    return await handleError( commit, err );
  }
};

const getContentItem = async({ commit }, { contentId }) => {
  try {
    const response = await venomExecute({
      query: GET_CONTENT_ITEM,
      variables: { contentId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setContentItem', response.content );
    return response.content;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createStream = async({ commit }, { collection, bundle }) => {
  try {
    const response = await venomExecute({
      query: CREATE_STREAM,
      variables: { input: { collection, bundle } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createVod = async({ commit }, { collection, bundle }) => {
  try {
    const response = await venomExecute({
      query: CREATE_VOD,
      variables: { input: { collection, bundle } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteApplication =  async({ commit }, { appId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_APPLICATION,
      variables: { appId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response?.deleteApplication?.application?.id ) {
      commit('deleteApp', appId)
      // await fetchAllOrgApps({ commit }, { orgId });
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateApplication = async({ state, commit }, { appId, inputFields }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_APPLICATION,
      variables: { input: { application: appId, ...inputFields } }
    });
    if ( inputFields.properties ) {
      const selectedAppProperties = state.org.appProperties?.filter( p => inputFields.properties.includes( p.id ) );
      commit( 'selectApplication', {
        ...state.selected.application,
        ...response.updateApplication.application,
        properties: selectedAppProperties
      } );
    } else {
      commit( 'selectApplication', {
        ...state.selected.application,
        ...response.updateApplication.application
      } );
    }
    if ( inputFields.name ) {
      commit( 'updateAppBreadcrumbName', { id: appId, ...inputFields } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
};

const generateToken = async({ commit }, { appId }) => {
  try {
    const response = await venomExecute({
      query: GENERATE_APPLICATION_TOKEN,
      variables: { appId }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'updateAppTokensList', response?.generateApplicationToken?.application?.tokens );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const saveOrgAppDeveloperKey = async({ commit }, { appId, inputFields }) => {
  try {
    const response = await venomExecute({
      query: SET_APPLICATION_DEV_KEYS,
      variables: { input: { application: appId, ...inputFields } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'updateAppDevKeys', response?.setApplicationData?.application?.allData || [] );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const getApplicationAssets = async ({ commit }, { appId }) => {
  try {
    const response = await venomExecute({
      query: GET_APPLICATION_ASSETS,
      variables: { appId, after: null }
    })
    if (response.errors) {
      throw new Error('Server error', response.errors[0]);
    }
    commit('setApplicationAssets', { applicationAssets : response.application?.assetsConnection?.nodes })
    return response
  } catch (err) {
    return await handleError( commit, err );
  }
}

const createApplicationAsset = async ({ commit }, input) => {
  try {
    const response = await venomExecute({
      query: CREATE_APPLICATION_ASSET,
      variables: { input }
    });
    if ( input.owner.content )
      await getContentItem({ commit }, { contentId: input.owner.content  });
    else
      commit('addApplicationAsset', { asset: response.createApplicationAsset?.asset });
    return response;
  } catch (err) {
    return await handleError(commit, err, false);
  }
}

const updateApplicationAsset = async ({ commit }, { input, contentId }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_APPLICATION_ASSET,
      variables: { input }
    });
    if ( contentId )
      await getContentItem({ commit }, { contentId });
    else
      commit ('updateApplicationAsset', { asset: response.updateApplicationAsset?.asset });
    return response;
  } catch (err) {
    return await handleError(commit, err, false);
  }
}
const createAdContent = async({ commit }, { collection, ads }) => {
  try {
    const response = await venomExecute({
      query: CREATE_AD_CONTENT,
      variables: { input: { collection, ads } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const createInteractiveContent = async({ commit }, { collection, story, interactive }) => {
  try {
    const response = await venomExecute({
      query: CREATE_INTERACTIVE_CONTENT,
      variables: { input: { collection, story, ...interactive } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.createInteractiveLink.interactive;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const getRootFolderSearchItems = async({ commit }, {orgId,mediaClasses,type,searchInput}) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_LIBRARY_SEARCH_ITEMS,
      variables: {
        organizationId: orgId,
        mediaClasses: mediaClasses,
        type: type,
        searchInput
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setMediaLibraryItems', {items: response.organization?.mediaLibrary?.folder?.search?.edges, after: null } );
    commit( 'setMediaFolders', [] );
    commit( 'setMediaLibraryCursor', { cursor: null, hasNextPage: false } );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const getFolderSearchItems = async({ commit }, {orgId,mediaClasses,type,searchInput, folderId}) => {
  try {
    const response = await venomExecute({
      query: GET_MEDIA_LIBRARY_FOLDER_SEARCH_ITEMS,
      variables: {
        organizationId: orgId,
        mediaClasses: mediaClasses,
        type: type,
        searchInput,
        folderId
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0]);
    }
    commit( 'setMediaLibraryItems', {items: response.organization.mediaLibrary.folder.search.edges, after: null } );
    commit( 'setMediaFolders', [] );
    commit( 'setMediaLibraryCursor', { cursor: null, hasNextPage: false } );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteApplicationAsset = async ({ commit }, input ) => {
  try{
    const response = await venomExecute({
      query: DELETE_APPLICATION_ASSET,
      variables: {
        input : { asset : input.asset }
      }
    });
    if (response.errors) {
      throw new Error('Server error: ', response.errors[0]);
    }
    if( input.content )
      await getContentItem({ commit }, { contentId: input.content  });
    else    
      commit('deleteApplicationAsset', { assetId: input.asset });

    return response.deleteApplicationAsset;
  } catch (err) {
    return await handleError( commit, err );
  }
}
const getAppTokenForPlayer = async ( { commit }, { orgId } ) => {
  try {
    const response = await venomExecute({
      query: GET_HTML_APPLICATION_TOKEN,
      variables: {
        input: {
          organization: orgId
        }
      }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const saveOrgNewIV = async({ commit }, { ivData }) => {
  try {
    const response = await venomExecute({
      query: CREATE_INTERACTIVE,
      variables: { input: ivData }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.createInteractive?.interactive?.id || false;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const selectIV = async({ commit }, { ivId }) => {
  try {
    const response = await venomExecute({
      query: GET_INTERACTIVE,
      variables: { interactiveId: ivId }
    }); 
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'selectIV', response.interactive );
    return response.interactive;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateInteractive = async({ commit }, { ivData }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_INTERACTIVE,
      variables: { input: ivData }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'updateSelectedIV', response.updateInteractive.change.current.name );
    return response?.updateInteractive?.change?.current?.name || false;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const deleteInteractive = async({ commit }, { interactive }) => {
  try {
    const response = await venomExecute({
      query: DELETE_INTERACTIVE,
      variables: { input: { interactive } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response.deleteInteractive?.change?.type;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const deleteContentLanguage = async({ commit }, { content, language }) => {
  try {
    const response = await venomExecute({
      query: DELETE_CONTENT_LANGUAGE,
      variables: { input: { content, language } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response ) {
      await getContentItem({ commit }, { contentId: content  });
      return response;
    }
    } catch (err) {
    return await handleError( commit, err );
  }
}

const setContentLanguage = async({ commit }, { content, variables }) => {
  try {
    const response = await venomExecute({
      query: SET_CONTENT_LANGUAGE,
      variables: { input: { content, ...variables } }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content  });
    return response;
    } catch (err) {
      return await handleError( commit, err, false );
  }
}

const createAd = async({ commit }, input) => {
  try {
    const response = await venomExecute({
      query: CREATE_AD,
      variables: { inputDirect: input }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.createAd
  } catch (err) {
    return await handleError( commit, err );
  }
}

const sendNotificationForAnApp = async ({ commit }, input) => {
  try {
    const response = await venomExecute({
      query: SEND_NOTIFICATION_TO_AN_APP,
      variables: { input }
    });
    if (response.errors) {
      return response.errors[0]
    }
    return response.sendNotificationForAnApp
  } catch (err) {
    if (err?.message.includes('Api key is missing')) {
      err.isError = true
      return err
    } else {
      return await handleError(commit, err);
    }
  }
}

const editMediaFolderName = async({ commit }, { folderId, name }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_MEDIA_FOLDER,
      variables: { input: { folder: folderId, name } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const deleteContentCamera = async ({ commit }, { cameraId, content }) => {
  try{
    const response = await venomExecute({
      query: DELETE_CONTENT_CAMERA,
      variables: {
        input: { camera: cameraId }
      }
    });
    if (response.errors) {
      throw new Error('Server error: ', response.errors[0]);
    }
    if ( response)
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const updateAd = async ({ commit }, input) => {
  try {
    const response = await venomExecute({
      query: UPDATE_AD,
      variables: { inputDirect: input }
    });
    if (response.errors) {
      throw new Error('Server error', response.errors[0]);
    }
    return response?.updateAd
  } catch (err) {
    return await handleError(commit, err);
  }
}

const createContentCamera = async ({ commit }, { media, content }) => {
  try{
    const response = await venomExecute({
      query: CREATE_CONTENT_CAMERA,
      variables: {
        input: {
          stream: content,
          media
        }
    }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
}

const updateContentCamera = async({ commit }, { camera, title, active, setToDefault, content }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_CONTENT_CAMERA,
      variables: { input: { camera: camera.id,  title, active, setToDefault } }
    });
    await getContentItem({ commit }, { contentId: content });
    if ( response ) {
      return response;
    }
    } catch (err) {
    return await handleError( commit, err, false );
  }
}

const requestPasswordReset = async ({ commit }, email) => {
  try {
    const response = await venomExecute({
      query: REQUEST_PASSWORD_RESET,
      variables: {
        email
      }
    });
    if (response.errors) {
      throw new Error('Server error: ', response.errors[0])
    }
    return response
  } catch (err) {
    return await handleError(commit, err);
  }
}

const resetPassword = async ({ commit }, { email, token, password }) => {
  try {
    const response = await venomExecute({
      query: RESET_PASSWORD,
      variables: {
        email,
        token,
        newPassword: password
      }
    })
    if (response.errors) {
      return response.errors[0]
    }
    return response?.resetCMSUserPassword
  } catch (err) {
    return await handleError(commit, err);
  }
}

const verifyUserEmail = async (commit, { email, token }) => {
  try {
    const response = await venomExecute({
      query: VERIFY_USER_EMAIL,
      variables: {
        email,
        token
      }
    })
    return response
  } catch (err) {
    return { errors: [err]}
  }
}

const resendOrgInvite = async (commit, { userId }) => {
  try {
    const response = await venomExecute({
      query: RESEND_ORG_INVITE,
      variables: {
        userId
      }
    })
    return response;
  } catch (err) {
    return { errors: [err]}
  }
}

const deleteContentArt = async({ commit }, { content, variables }) => {
  try {
    const response = await venomExecute({
      query: DELETE_CONTENT_ART,
      variables: { input: { content, ...variables } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response ) {
      await getContentItem({ commit }, { contentId: content  });
      return response;
    }
    } catch (err) {
      return await handleError( commit, err );
  }
}

const updateContentArt = async({ commit }, { content, variables }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_CONTENT_ART,
      variables: { input: { content, ...variables } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response )
      await getContentItem({ commit }, { contentId: content  });
    } catch (err) {
      return await handleError( commit, err );
  }
}

const getAd = async ({ commit }, { orgId, adId }) => {
  try {
     const response = await venomExecute({
       query: GET_AD,
       variables: {
         orgId,
         adId
       }
     })
     if (response.errors) {
       throw new Error('Server error', response.errors[0]);
     }
     return response?.ad
  } catch (err) {
    return await handleError(commit, err);
  }
}

const deleteNotification = async({ commit }, { notificationId }) => {
  try {
    const response = await venomExecute({
      query: DELETE_NOTIFICATION,
      variables: { input: { notification: notificationId } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response;
  }
  catch (err) {
    return await handleError( commit, err );
  }
};

const getOrgPermissions = async ({ commit }, orgId) => {
  try {
    if (!orgId) {
      commit('setViewer', {});
      return
    }
    const response = await venomExecute({
      query: GET_ORG_PERMISSIONS,
      variables: {
        orgId
      }
    })
    if (response.errors) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit('setViewer', response?.viewer);
    return response.getOrganizationPermissions
  } catch (err) {
    return await handleError(commit, err);
  }
}
const createVodFormat = async ({ commit }, { media, content }) => {
  try{
    const response = await venomExecute({
      query: CREATE_VOD_FORMAT,
      variables: {
        input: {
          vod: content,
          media: media.id,
          description: media.title
        }
      }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
}

const deleteVodFormat = async ({ commit }, { format, content }) => {
  try{
    const response = await venomExecute({
      query: DELETE_VOD_FORMAT,
      variables: {
        input: { vodFormat: format }
      }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
}

const updateVodFormat = async ({ commit }, { input, content }) => {
  try{
    const response = await venomExecute({
      query: UPDATE_VOD_FORMAT,
      variables: {
        ...{ input }
      }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
}

const addContentSubtitles = async({commit},{content,subtitles}) => {
  try{
    const response = await venomExecute({
      query: UPDATE_VOD_SETTINGS,
      variables: {
        input: {
          content,
          subtitles
        }
    }
    });
    if (response.errors) {
      return response.errors[0]
      // throw new Error('Server error: ', response.errors[0]?.message);
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const setMediaData = async({ commit }, { media, key, value }) => {
  try{
    const response = await venomExecute({
      query: SET_MEDIA_DATA,
      variables: {
        input: {
          media,
          key,
          value
        }
    }
    });
    if (response.errors) {
      throw new Error('Server error: ', response.errors[0]);
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsTopCountries = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_TOP_COUNTRIES,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAnalyticsData', response.organization.analytics );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsAllPlatforms = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_ALL_PLATFORMS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAnalyticsData', response.organization.analytics );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsUniqueAndActiveUsers = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_UNIQUE_AND_ACTIVE_USERS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'visitors-data-tab' ) {
      commit( 'setAnalyticsVisitorsDataTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsAvgViewAndAppTime = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_AVG_VIEW_AND_APP_TIME,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'time-data-tab' ) {
      commit( 'setAnalyticsTimeDataTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsPopularPlatforms = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_POPULAR_PLATFORMS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'platforms-tab' ) {
      commit( 'setAnalyticsPlatformsTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsPopularCategories = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_POPULAR_CATEGORIES,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'publishing-tab' ) {
      commit( 'setAnalyticsPublishingTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsPopularCollections = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_POPULAR_COLLECTIONS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'collections-tab' ) {
      commit( 'setAnalyticsCollectionsTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsVodUniqueViews = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_VOD_UNIQUE_VIEWS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'vod-data-tab' ) {
      commit( 'setAnalyticsVodDataTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsUniqueCompletedVods = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_UNIQUE_COMPLETED_VODS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'vod-data-tab' ) {
      commit( 'setAnalyticsVodDataTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsTopLiveStreams = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_TOP_LIVE_STREAMS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'live-streams-tab' ) {
      commit( 'setAnalyticsLiveStreamsTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsTopStories = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_TOP_STORIES,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAnalyticsData', response.organization.analytics );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsVideoDownloads = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_VIDEO_DOWNLOADS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAnalyticsData', response.organization.analytics );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsLocationBasedViews = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'visitors-data-tab' ) {
      commit( 'setAnalyticsVisitorsDataTab', { locationBasedViews: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedViews: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedActiveUsers = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'visitors-data-tab' ) {
      commit( 'setAnalyticsVisitorsDataTab', { locationBasedActiveUsers: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedActiveUsers: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedViewTimes = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'time-data-tab' ) {
      commit( 'setAnalyticsTimeDataTab', { locationBasedViewTimes: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedViewTimes: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedAppTimes = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'time-data-tab' ) {
      commit( 'setAnalyticsTimeDataTab', { locationBasedAppTimes: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedAppTimes: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedPlatforms = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'platforms-tab' ) {
      commit( 'setAnalyticsPlatformsTab', { locationBasedPlatforms: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedPlatforms: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedCategories = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'publishing-tab' ) {
      commit( 'setAnalyticsPublishingTab', { locationBasedCategories: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedCategories: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsLocationBasedCollections = async ( { commit }, { query, input } ) => {
  try {
    const response = await venomExecute({
      query: query,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'collections-tab' ) {
      commit( 'setAnalyticsCollectionsTab', { locationBasedCollections: response.organization.analytics } );
    } else {
      commit( 'setAnalyticsData', { locationBasedCollections: response.organization.analytics } );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
}

const analyticsUniqueViews = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_UNIQUE_VIEWS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'vod-data-tab' ) {
      commit( 'setAnalyticsVodDataTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsLiveStreams = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_LIVE_STREAMS,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'live-streams-tab' ) {
      commit( 'setAnalyticsLiveStreamsTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsUsagesData = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_USAGES_DATA,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.ref === 'usages-tab' ) {
      commit( 'setAnalyticsUsagesTab', response.organization.analytics );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsXrActiveUsersCount = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_XR_ACTIVE_USERS_COUNT,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    commit( 'setAnalyticsData', response.organization.analytics );
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const analyticsApiResponseTimes = async ( { commit }, input ) => {
  try {
    const response = await venomExecute({
      query: ANALYTICS_API_RESPONSE_TIMES,
      variables: input
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( input.apiKey === 'property' ) {
      commit( 'setAnalyticsData', { propertyApiResponseTimes: response.organization.apiResponseTimes } );
    } else if ( input.apiKey === 'collections' ) {
      commit( 'setAnalyticsData', { collectionsApiResponseTimes: response.organization.apiResponseTimes } );
    } else if ( input.apiKey === 'content' ) {
      commit( 'setAnalyticsData', { contentApiResponseTimes: response.organization.apiResponseTimes } );
    } else if ( input.apiKey === 'applications' ) {
      commit( 'setAnalyticsData', { applicationsApiResponseTimes: response.organization.apiResponseTimes } );
    } else {
      commit( 'setAnalyticsData', response.organization.analytics );
    }
    return response;
  } catch (err) {
    return await handleError( commit, err );
  }
};

const updateContentPermissions = async ({commit},{content,appRestrictions,geoRestrictions}) => {
  try{
    const response = await venomExecute({
      query: UPDATE_CONTENT,
      variables: {
        input: {
          content,
          appRestrictions,
          geoRestrictions
        }
      }
    });
    if ( response )
      await getContentItem({ commit }, { contentId: content });
    return response;
  } catch (err) {
    return await handleError( commit, err, false );
  }
}


const createAr = async({ commit }, input) => {
  try {
    const response = await venomExecute({
      query: CREATE_AR_CONTENT,
      variables: { input }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    return response?.createArContent
  } catch (err) {
    return await handleError( commit, err );
  }
}


const updateArContent = async({ commit }, { ar, variables }) => {
  try {
    const response = await venomExecute({
      query: UPDATE_AR_CONTENT,
      variables: { input: { ar, ...variables } }
    });
    if ( response.errors ) {
      throw new Error( 'Server error: ', response.errors[0] );
    }
    if ( response ) {
      await getContentItem({ commit }, { contentId: ar  });
      return response;
    }
    } catch (err) {
      return await handleError( commit, err );
  }
}

export default {
  clearError,
  clearOrganization,
  logoutUser,
  loginUser,
  getAvailableOrgs,
  selectOrganization,
  selectApplication,
  fetchTranscodes,
  fetchInteractives,
  fetchAds,
  fetchUsers,
  fetchPushNotifications,
  reTranscode,
  fetchUserDetails,
  setPropertyPermission,
  removeUserFromOrg,
  updateUserInfo,
  fetchOrgSummary,
  fetchMediaLibrarySummary,
  fetchPublishingSummary,
  fetchAdsSummary,
  fetchAppsSummary,
  fetchProperties,
  fetchCollections,
  fetchContent,
  fetchMediaLibraryItems,
  fetchUsersSummary,
  fetchIvSummary,
  deleteProperty,
  setContentPosition,
  updateUserPassword,
  getAppProperties,
  createMedia,
  getAWSConfig,
  signRequest,
  createMediaBundle,
  saveOrgNewApp,
  registerCMSUser,
  deleteMedia,
  deleteMediaBundle,
  deleteMediaFolder,
  fetchMediaLibraryFolderItems,
  updateMediaFolder,
  updateMedia,
  updateMediaBundle,
  updateCollection,
  deleteCollection,
  deleteContent,
  fetchOrgCollections,
  updateContent,
  copyContentDeep,
  fetchMediaBundle,
  deleteApplication,
  updateApplication,
  generateToken,
  saveOrgAppDeveloperKey,
  createFolder,
  createProperty,
  createCollection,
  transcodeMedia,
  reprobeMedia,
  fetchMedia,
  updateProperty,
  inviteUser,
  editOrg,
  createOrg,
  deleteAd,
  getContentItem,
  createStream,
  createVod,
  getApplicationAssets,
  createApplicationAsset,
  updateApplicationAsset,
  createAdContent,
  getRootFolderSearchItems,
  getFolderSearchItems,
  deleteApplicationAsset,
  getAppTokenForPlayer,
  saveOrgNewIV,
  selectIV,
  updateInteractive,
  deleteInteractive,
  createInteractiveContent,
  fetchAllOrgApps,
  setContentLanguage,
  deleteContentLanguage,
  createAd,
  updateAd,
  sendNotificationForAnApp,
  deleteContentCamera,
  updateContentCamera,
  createContentCamera,
  requestPasswordReset,
  resetPassword,
  deleteContentArt,
  updateContentArt,
  getAd,
  deleteNotification,
  editMediaFolderName,
  getOrgPermissions,
  deleteVodFormat,
  updateVodFormat,
  createVodFormat,
  addContentSubtitles,
  setMediaData,
  analyticsTopCountries,
  analyticsAllPlatforms,
  analyticsUniqueAndActiveUsers,
  analyticsAvgViewAndAppTime,
  analyticsPopularPlatforms,
  analyticsPopularCategories,
  analyticsPopularCollections,
  analyticsVodUniqueViews,
  analyticsUniqueCompletedVods,
  analyticsTopLiveStreams,
  analyticsTopStories,
  analyticsVideoDownloads,
  analyticsLocationBasedViews,
  analyticsLocationBasedActiveUsers,
  analyticsLocationBasedViewTimes,
  analyticsLocationBasedAppTimes,
  analyticsLocationBasedPlatforms,
  analyticsLocationBasedCategories,
  analyticsLocationBasedCollections,
  analyticsUniqueViews,
  analyticsLiveStreams,
  analyticsUsagesData,
  analyticsXrActiveUsersCount,
  analyticsApiResponseTimes,
  updateContentPermissions,
  setUserDetails,
  getViewer,
  clearInteractives,
  verifyUserEmail,
  fetchMediaItemStatus,
  resendOrgInvite,
  createAr,
  updateArContent
};
