import moment from 'moment';
import { DATE_FORMAT } from '@/constants.js';

export default function filters ( Vue ) {

Vue.filter( 'formatDate', ( date, format = DATE_FORMAT ) => {
    return moment( date ).format( format );
} );

Vue.filter( 'date', value => {
    if ( !value ) return '';
    value = new Date( value );
    const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return `${monthNames[value.getMonth()]}. ${value.getDate()}, ${value.getFullYear()}`;
});

Vue.filter( 'appropriateBytes', ( sizeValue, options = {}) => {
    let bytes = 0;
    let size = 0;
    let sizeType;
    if ( typeof sizeValue === 'object' ) {
        [ sizeType ] = Object.keys( sizeValue );
        [ size ] = Object.values( sizeValue );
    } else bytes = Number(sizeValue);
    const {
        decimals = 2,
        space = ' '
    } = options;

    if ( bytes || sizeType === 'bits' || sizeType === 'bytes' ) {
        if ( sizeType === 'bits' ) bytes = size / 8;
        else bytes = size || bytes;
        const spaceChar = space === false ? '' : space;

        if ( bytes >= 1073741824 ) return `${( bytes / 1073741824 ).toFixed( decimals )}${spaceChar}GB`;

        if ( bytes >= 1048576 ) return `${( bytes / 1048576 ).toFixed( decimals )}${spaceChar}MB`;

        if ( bytes >= 1024 ) return `${( bytes / 1024 ).toFixed( decimals )}${spaceChar}KB`;

        if ( bytes > 1 ) return `${bytes} bytes`;

        if ( bytes === 1 ) return `${bytes} byte`;
    }
    if ( sizeType === 'kb' ) return `${size} KB`;
    if ( sizeType === 'mb' ) return `${size} MB`;
    if ( sizeType === 'gb' ) return `${size} GB`;
    if ( sizeType === 'megabits' ) return `${size} Mbits`;
    return '0 bytes';
});

Vue.filter( 'number', ( num, options ) => {
    let parsed = parseFloat( num );
    if ( Number.isNaN( parsed ) )
        parsed = 0;
    return parsed.toLocaleString( options );
});

}