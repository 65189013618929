import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const venomModule = {
  state: {
    connected: false,
    connectionError: '',
    serverError: '',
    org: {},
    user: {},
    selected: {}
  },
  mutations,
  actions,
  getters
}

export default venomModule; 
